import React from "react";
import DateCard from "../../components/DateCard/DateCard";
import GreenButton from "../../components/GreenButton/GreenButton";
import SelectInput from "../../components/SelectInput/SelectInput";
import "./Booking.styles.scss";

export default function Booking() {
  return (
    <div className="App">
      <div className="Container" data-aos="fade-up">
        <div className="booking">
          <DateCard placeholder="Arrival Date" />
          <DateCard placeholder="Depature Date" />
          <SelectInput />
          <GreenButton text="Find now" />
        </div>
      </div>
    </div>
  );
}
