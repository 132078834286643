import React from 'react';
import "./GreenButton.styles.scss";
    
export default function GreenButton({text}) {
    return (
        <button className="btn-container">
            {text}
        </button>
    )
}
