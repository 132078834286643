import "./ServiceBanner.styles.scss";
import Fade from "react-reveal"
export default function Banner({ props }) {
  return (
    <div className="contact-banner">
      <Fade bottom>
      <div>
        <h2>Our Services</h2>
        <p>
        Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
      </div>
      </Fade>
    </div>
  );
}
