import React from "react";
import { img, headline, description } from "./data";
import "./AboutUs.styles.scss";

export default function AboutUs() {
  return (
    <div className="App parent">
      <div className="Container about-us">
        <section data-aos="fade-right">
          <img src={img} alt="hotel" />
        </section>
        <section data-aos="fade-left">
          <h2>{headline}</h2>
          <p>{description}</p>
        </section>
      </div>
    </div>
  );
}
