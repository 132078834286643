import React from "react";
import "./TestimonialCardStyle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeftAlt } from "@fortawesome/free-solid-svg-icons";
export default function TestimonialCard({
  image,
  name,
  position,
  ref3,
  description,
}) {
  const quote = <FontAwesomeIcon icon={faQuoteLeftAlt} size="2xs" />;

  return (
    <div className="testimonial__card__container" data-aos="fade-up" ref={ref3}>
      <div className="quote">{quote}</div>
      <div className="testimonial__card__text">
        <blockquote>
          <p>{description}</p>
        </blockquote>
      </div>
      <div className="testimonial__card__author">
        <img src={image} alt="" className="testimonial__card__icon" />
        <div className="testimonial__card__position">
          <span className="author__name">{name}</span>
          <span className="author__job">{position}</span>
        </div>
      </div>
    </div>
  );
}
