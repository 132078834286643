import React from "react";
import "./Contact.styles.scss";
import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaDribbble,
} from "react-icons/fa";

const icons = [
  <FaInstagram />,
  <FaFacebookF />,
  <FaTwitter />,
  <FaLinkedinIn />,
  <FaDribbble />,
];

// ---------- body ---------------
const ContactBody = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="contact-container">
      <div className="contact-left">
        <div className="contact-left-heading">
          <h2>GET IN TOUCH</h2>
          <p>
            Far far away, behind the word mountains, far from the countries
            Vokalia and Consonantia, there live the blind texts. Separated they
            live in Bookmarksgrove right at the coast of the Semantics, a large
            language ocean.
          </p>
        </div>
        <form onSubmit={handleSubmit} className="contact-left-form">
          <div className="form-top">
            <div>
              <small>Name</small>
              <input type="text" />
            </div>
            <div>
              <small>Email</small>
              <input type="email" />
            </div>
          </div>
          <div className="form-bottom">
            <small>Message</small>
            <textarea rows="10"></textarea>
          </div>
          <button type="submit" className="form-btn">
            Send Message
          </button>
        </form>
      </div>
      <div className="contact-right">
        <div className="contact-right-container">
          <div>
            <h4>Address</h4>
            <p>43 Raymouth Rd. Baltemoer, London 3910</p>
          </div>
          <div>
            <h4>Phone</h4>
            <p>+1 234 567 890</p>
            <p>+1 234 567 890</p>
          </div>
          <div>
            <h4>Follow</h4>
            <div className="icons">
              {icons.map((item, index) => {
                return (
                  <a className="contact-icon" key={index} href="/#">
                    {item}
                  </a>
                );
              })}
            </div>
          </div>
          <div>
            <h4>Email</h4>
            <a href="mailto:info@hotel.com">info@mydomain.com</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactBody;
