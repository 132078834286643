import image1 from "./images/posts/1.webp";
import image2 from "./images/posts/2.webp";
import image3 from "./images/posts/3.webp";
export const postBanners = [
  image1,
  image2,
  image3,
  image1,
  image2,
  image3,
  image1,
  image2,
  image3,
  image1,
  image2,
  image3,
  image1,
  image2,
  image3,
  image1,
  image2,
  image3,
];
