import { useState, useEffect } from "react";
import { GiTreasureMap } from "react-icons/gi";
import { GiPalmTree } from "react-icons/gi";
import { FcGlobe } from "react-icons/fc";
import { GiCommercialAirplane } from "react-icons/gi";
import { GiWalk } from "react-icons/gi";
import { RiSuitcase2Line } from "react-icons/ri";
import Button from "../../components/Button/Button";
import ServicesCard from "./ServicesCard";
import "./OurServices.styles.scss";

const services = [
  { title: "Trekking", icon: <GiWalk className="icon" /> },
  { title: "The Map", icon: <GiTreasureMap className="icon" /> },
  { title: "Suitcase", icon: <RiSuitcase2Line className="icon" /> },
  { title: "Island Hoping", icon: <GiPalmTree className="icon" /> },
  { title: "World Round", icon: <FcGlobe className="icon" /> },
  {
    title: "Travel with Plane",
    icon: <GiCommercialAirplane className="icon" />,
  },
];

const OurServices = () => {
  const width = getComputedStyle(document.documentElement).getPropertyValue(
    "--service-carousel-width"
  );

  const [idx, setIdx] = useState(0);

  const [transform, setTransform] = useState(30);

  const style = {
    transform: `translateX(-${transform * idx + 2}%)`,
  };

  useEffect(() => {
    let mql1 = window.matchMedia("(max-width: 768px)");
    let mql2 = window.matchMedia("(max-width: 1024px)");
    // let mql3 = window.matchMedia("(min-width: 1024px)");
    window.addEventListener("resize", () => {
      if (mql1.matches) {
        setTransform(100);
      } else if (mql2.matches) {
        setTransform(50);
      } else setTransform(30);
    });
  }, []);

  const [gap, setGap] = useState(0);

  const carouselWidth = parseFloat(width.split("%")[0].split(" ")[1]);

  useEffect(() => {
    if (carouselWidth === 45) {
      setGap(4);
    }
    if (carouselWidth === 100) {
      setGap(5);
    }
  }, [carouselWidth]);

  return (
    <main className="our-services">
      <div className="services-backgound"></div>
      <div className="">
        <h2 className="our-services-header">Our Services</h2>
      </div>

      <div className="">
        <div className="carousel" style={style}>
          {services.map(({ title, icon }) => (
            <ServicesCard key={title} icon={icon} />
          ))}
        </div>

        <div className="mobile caruosel"></div>
      </div>

      <div className="our-services-btn">
        <div className="nav-btn">
          <Button
            onClick={() => {
              setIdx((prev) => {
                if (prev === 0) {
                  setGap(gap);
                  return services.length - 1;
                } else {
                  return prev - 1;
                }
              });
            }}
          >
            Prev
          </Button>

          <Button
            onClick={() => {
              setIdx((prev) => {
                if (prev === services.length - 1) {
                  return 0;
                } else {
                  return prev + 1;
                }
              });
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </main>
  );
};

export default OurServices;
