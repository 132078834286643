import image1 from "./images/1.jpg";
import image2 from "./images/2.jpg";
import image3 from "./images/3.jpg";
import image4 from "./images/4.webp";
import image5 from "./images/5.webp";


export const data = [
  {
    name: "Hannah White",
    position: "CEO, Co-Founder",
    image: image1,
    description:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. ",
  },
  {
    name: "James Scott",
    position: "CEO, Co-Founder",
    image: image2,
    description:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. ",
  },
  {
    name: "Brian Nance",
    position: "CEO, Co-Founder",
    image:image3,
    description:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. ",
  },
  {
    name: "Amy Perez",
    position: "CEO, Co-Founder",
    image:image4,
    description:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. ",
  },
  {
    name: "Stephanie Kenney",
    position: "CEO, Co-Founder",
    image:image5,
    description:
      "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. ",
  },
];
