import React, { useState, useRef, useEffect } from "react";
import { data } from "../data";
import { WhoWeAreCard } from "../WhoWeAreCard/WhoWeAreCard";
import Button from "../../../components/Button/Button";
import Fade from "react-reveal/Fade";

const WhoWeAre = () => {
  const [nav, setNav] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [outerWidth, setOuterWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [reverse, setReverse] = useState(0);
  const showPrev = reverse;
  const showNext = nav + outerWidth < totalWidth;
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    setTotalWidth(ref1.current.clientWidth);
    setOuterWidth(ref2.current.clientWidth);
    setCardWidth(ref3.current.clientWidth);
    console.log(nav);
  }, [nav]);

  const handleNextOnClick = () => {
    if (nav >= cardWidth * 5) {
      return setNav(cardWidth * 5);
    }
    if (showNext) {
      setNav(nav + cardWidth);
      setReverse((prevS) => prevS + 1);
    }
  };

  const handlePrevOnClick = () => {
    if (showPrev) {
      setNav(nav - cardWidth);
      setReverse((prevS) => prevS - 1);
    }
  };

  return (
    <>
      <div className="whoweare__outer__container">
        <div className="align">
          <div className="whoweare__text__container">
            <Fade bottom>
              {" "}
              <h1>WHO WE ARE</h1>
            </Fade>
            <Fade bottom>
              <p>
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts. Separated
                they live in Bookmarksgrove right at the coast of the Semantics,
                a large language ocean.
              </p>
            </Fade>
          </div>
        </div>
        <div className="align">
          <Fade bottom>
            {" "}
            <div className="whoweare__cards__container">
              <div ref={ref2} className="whoweare__carousel__container">
                <div
                  ref={ref1}
                  className="whoweare__carousel"
                  style={{
                    transform: `translateX(-${nav + 30}px)`,
                  }}
                >
                  {data.map((item, i) => (
                    <WhoWeAreCard
                      ref3={ref3}
                      key={i}
                      name={item.name}
                      image={item.image}
                      description={item.description}
                      position={item.position}
                    />
                  ))}
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <div className="whoweare__button__container">
          <div className="align">
            <Button onClick={handlePrevOnClick} showBtn={showPrev}>
              Prev
            </Button>
            <Button onClick={handleNextOnClick} showBtn={showNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
