import { useState, useRef, useEffect } from "react";
import Button from "../../components/Button/Button";
import TestimonialCard from "./TestimonialCard/TestimonialCard";
import "./Testimonials.styles.scss";
import image1 from "./images/1.webp";
import image2 from "./images/2.webp";
const Testimonial = () => {
  const [nav, setNav] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  const [outerWidth, setOuterWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [reverse, setReverse] = useState(0);
  const showPrev = reverse;
  const showNext = nav + outerWidth < totalWidth;
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    setTotalWidth(ref1.current.clientWidth);
    setOuterWidth(ref2.current.clientWidth);
    setCardWidth(ref3.current.clientWidth);
  }, [cardWidth]);

  const handleNextOnClick = () => {
    if (nav >= cardWidth * 5) {
      return setNav(cardWidth * 5);
    }
    if (showNext) {
      setNav(nav + cardWidth * 1.12);
      setReverse((prevS) => prevS + 1);
    }
  };

  const handlePrevOnClick = () => {
    if (showPrev) {
      setNav(nav - cardWidth * 1.12);
      setReverse((prevS) => prevS - 1);
    }
  };

  const data = [
    {
      ref3: ref3,
      name: "John Doe",
      position: "Creative Director",
      description:
        "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.",
      image: image1,
    },
    {
      ref3: ref3,
      name: "Jane Doe",
      position: "Creative Director",
      description:
        "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.",
      image: image2,
    },
    {
      ref3: ref3,
      name: "John Doe",
      position: "Creative Director",
      description:
        "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.",
      image: image1,
    },
    {
      ref3: ref3,
      name: "Jane Doe",
      position: "Creative Director",
      description:
        "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.",
      image: image2,
    },
    {
      ref3: ref3,
      name: "John Doe",
      position: "Creative Director",
      description:
        "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.",
      image: image1,
    },
  ];

  return (
    <div className="testimonials-container">
      <div className="testimonials Container">
        <div className="testimonial-header-container">
          <h2 className="testimonial-header" data-aos="fade-right">
            Testimonials
          </h2>

          <div className="testimonial-par-container">
            <p className="testimonial-par" data-aos="fade-left">
              A small river named Duden flows by their place and supplies it
              with the necessary regelialia. It is a paradisematic country, in
              which roasted parts of sentences fly into your mouth.
            </p>
          </div>
        </div>
        <div ref={ref2} className="our-services-carousel-container">
          <div
            ref={ref1}
            className="testimonials-carousel"
            data-aos="fade-up"
            style={{
              transform: `translateX(-${nav}px)`,
            }}
          >
            {data.map((el) => (
              <TestimonialCard
                ref3={el.ref3}
                name={el.name}
                position={el.position}
                image={el.image}
                description={el.description}
              />
            ))}
          </div>
          <div className="testimonial-btns" data-aos="fade-up">
            <Button onClick={handlePrevOnClick} showBtn={showPrev}>
              Prev
            </Button>
            <Button onClick={handleNextOnClick} showBtn={showNext}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
