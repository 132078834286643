import Banner from "../../sections/ServiceBanner/ServiceBanner";
import OurServices from '../../sections/OurServices/OurServices';
import AboutUs from "../../sections/AboutUs/AboutUs";
import { useContext, useEffect } from "react";
import { UserContext } from "../../App";

export default function Services() {
	const [navigation, setNavigation] = useContext(UserContext);

	useEffect(() => {
    setNavigation("services");
  }, [])

	return (
		<>
			<Banner />
			<OurServices />
			<AboutUs />
		</>
	);
}