import blog1 from "../../assets/images/blog/blog1.webp";
import blog2 from "../../assets/images/blog/blog2.webp";
import blog3 from "../../assets/images/blog/blog3.webp";
import blog4 from "../../assets/images/blog/blog4.webp";
import blog5 from "../../assets/images/blog/blog5.webp";

const blogData = [
  {
    img: blog1,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    img: blog2,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    img: blog3,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    img: blog4,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
  {
    img: blog5,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
  },
];

export default blogData;
