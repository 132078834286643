import React, { useState, useEffect, useRef } from "react";
import "../styles.scss";
import arrow from "./arrow.svg";

export default function Accordion({ title, content, content2 }) {
  const [toggle, setToggle] = useState(false);
  const [heightEl, setHeightEl] = useState();
  const refHeight = useRef();

  useEffect(() => {
    setHeightEl(`${refHeight.current.scrollHeight}px`);
  }, []);

  const toggleState = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <div className="accordion">
        <button onClick={toggleState} className="accordion-visible">
          <span>{title}</span>

          <img className={toggle && "active"} alt="" src={arrow} />
        </button>

        <div
          className={toggle ? "accordion-toggle animated" : "accordion-toggle"}
          style={{ height: toggle ? `${heightEl}` : "0px" }}
          ref={refHeight}
        >
          <p aria-hidden={toggle ? "true" : "false"}>{content}</p>
          <p>{content2}</p>
        </div>
      </div>
    </>
  );
}
