export const WhoWeAreCard = ({ name, position, description, image, ref3 }) => {
  return (
    <>
      <div className="whoweare__card__container" ref={ref3}>
        <div className="whoweare__image_container">
          <img src={image} alt="Person" />
        </div>
        <div className="whoweare__name">
          <span>{name}</span>
        </div>
        <div className="whoweare__position">
          <span>{position}</span>
        </div>
        <div className="whoweare__description">
          <p>{description}</p>
        </div>
        <div className="whoweare__learnmore">
          <span>
            <a href="/#">Learn more</a>
          </span>
        </div>
      </div>
    </>
  );
};
