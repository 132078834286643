import React from "react";

import destinationBg from "../../assets/images/header/destination-bg.webp";
import Header from "../../sections/Header/Header";
import "./Destination.styles.scss";

import { destinationData } from "../../assets/destinationData";
import PlaceCard from "../../components/PlaceCard/PlaceCard";

const Destination = () => {
  return (
    <div>
      <Header bgImage={destinationBg} text="Popular places" />
      <div className="Container destination-container">
        <h2 className="destination-header">SPECIAL OFFERS & DISCOUNTS</h2>
        <p className="destination-text">
          Far far away, behind the word mountains, far from the countries
          Vokalia and Consonantia, there live the blind texts. Separated they
          live in Bookmarksgrove right at the coast of the Semantics, a large
          language ocean.
        </p>
        <div className="destination-card-container">
          {destinationData.map((item) => (
            <PlaceCard key={item.id} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Destination;
