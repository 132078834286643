import Accordion from "./Accordion/Accordion";
import { accordionData } from "../FAQ/Accordion/content/content";

export default function Faq() {
  return (
    <section className="faq__section__container">
      <div className="faq__inner__container Container" data-aos="fade-right">
        <div className="faq__section">
          <div className="questions_container">Frequently Asked Questions</div>
        </div>
        <div className="faq__container" data-aos="fade-left">
          {accordionData.map((item, i) => (
            <Accordion
              selected={false}
              title={item.title}
              content={item.content}
              content2={item.content2}
              index={i}
            />
          ))}
        </div>
      </div>
      <div className="filler"></div>
    </section>
  );
}
