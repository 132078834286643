import React, { useEffect, useState } from "react";
import "./Blog.styles.scss";
import blogData from "./blogData";
import { FaPencilAlt } from "react-icons/fa";

const Blog = () => {
  const [value, setValue] = useState(1);

  useEffect(() => {
    let lastValue = blogData.length;
    if (value < 1) {
      setValue(lastValue);
    }
    if (value > lastValue) {
      setValue(1);
    }
  }, [value]);

  useEffect(() => {
    // time out will trigger an automatic "next" every 5 seconds
    const timer = setTimeout(() => {
      setValue(value + 1);
    }, 5000);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <>
      <div className="Container">
        <h2 className="blog-header" data-aos="fade-up">
          BLOG POSTS
        </h2>
      </div>
      <div className="section-blog">
        <div className="blog-container">
          <div className="blogs" data-aos="fade-up">
            {blogData.map((item, index) => {
              let position = "next-slide-hide";
              if (index === value) {
                position = "active-slide";
              }
              if (index === value - 1) {
                position = "prev-slide";
              }
              if (index === value + 1) {
                position = "next-slide";
              }
              if (index === value - 2) {
                position = "next-slide-hide";
              }
              if (index === 0 && value === blogData.length - 1) {
                position = "next-slide";
              }
              if (index === 0 && value === blogData.length) {
                position = "active-slide";
              }
              if (index === 1 && value === blogData.length) {
                position = "next-slide";
              }

              return (
                <div key={index} className={`blog ${position}`}>
                  <div className="blog-img">
                    <img src={item.img} alt="blog-img" />
                    <span>{<FaPencilAlt />}</span>
                  </div>
                  <div className="blog-text">
                    <h4>{item.text}</h4>
                    <a href="/#">Read more</a>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="blog-btn">
            <button onClick={() => setValue(value - 1)}>Prev</button>
            <button onClick={() => setValue(value + 1)}>Next</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
