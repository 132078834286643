import image1 from "./images/place1.webp";
import image2 from "./images/place2.webp";
import image3 from "./images/place3.webp";
import image4 from "./images/place4.webp";

export const destinationData = [
  {
    id: 1,
    title: "Kaafu Atoll",
    location: "Maldives",
    price: 340,
    image: image1,
  },
  {
    id: 2,
    title: "The White City",
    location: "Greece",
    price: 620,
    image: image2,
  },
  {
    id: 3,
    title: "Byodo-In Temple",
    location: "United States",
    price: 530,
    image: image3,
  },
  {
    id: 4,
    title: "Byodo-In Temple",
    location: "Dubai",
    price: 870,
    image: image4,
  },
  {
    id: 5,
    title: "Kaafu Atoll",
    location: "Maldives",
    price: 340,
    image: image1,
  },
  {
    id: 6,
    title: "The White City",
    location: "Greece",
    price: 620,
    image: image2,
  },
  {
    id: 7,
    title: "Byodo-In Temple",
    location: "United States",
    price: 530,
    image: image3,
  },
  {
    id: 8,
    title: "Byodo-In Temple",
    location: "Dubai",
    price: 870,
    image: image4,
  },
];
