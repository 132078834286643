import React from "react";
import "./SelectInput.styles.scss";

function SelectInput() {
  const numOfGuests = [1, 2, 3, 4, 5];

  return (
    <div className="person-select">
      <select>
        <option>Number of guests</option>
        {numOfGuests.map((number) => {
          return (
            <option key={number} value={number}>
              {number}
            </option>
          );
        })}
      </select>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="17"
        height="17"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M4 22a8 8 0 1 1 16 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
      </svg>
    </div>
  );
}

export default SelectInput;
