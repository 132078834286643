import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createContext, useState } from "react";
import "aos/dist/aos.css";
import AOS from "aos";
import "./App.css";
import HomePage from "./pages/HomePage/HomePage";
import ErrorPage from "./sections/ErrorPage/ErrorPage.jsx";
import Services from "./pages/Services/Services.jsx";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/Aboutus.jsx";
import Navbar from "./sections/NavBar/Navbar";
import Test from "./sections/Test/Test";
import InstagramPosts from "./sections/InstagramPosts/InstagramPosts";
import Footer from "./sections/Footer/Footer";
import Destination from "./pages/Destination/Destination";

export const UserContext = createContext();

export default function App() {
  const [navigation, setNavigation] = useState("");
  AOS.init({ once: true, duration: 1500 });
  return (
    <UserContext.Provider value={[navigation, setNavigation]}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/destination" element={<Destination />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/test" element={<Test />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
        <InstagramPosts />
        <Footer />
      </BrowserRouter>
    </UserContext.Provider>
  );
}
