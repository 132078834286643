import React from "react";
import "./Contact.styles.scss";
import ContactBody from "./ContactBody";

import { useContext, useEffect } from "react";
import { UserContext } from "../../App";
import Fade from "react-reveal"
const Contact = () => {
  const [navigation, setNavigation] = useContext(UserContext);

  useEffect(() => {
    setNavigation("contact");
  });

  return (
    <div className="">
      <ContactBanner />
      <ContactBody />
    </div>
  );
};

export default Contact;

// ---------- Banner --------------
const ContactBanner = () => {
  return (
    <div className="contact-banner">
      <div>
      <Fade bottom> 
        <h2>Contact</h2>
        <p>
        Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.
        </p>
        </Fade>
      </div>
      
    </div>
  );
};
