import FAQ from "../../sections/FAQ/FAQ";
import AboutUs from "../../sections/AboutUs/AboutUs";
import OurServices from "../../sections/OurServices/OurServices";
import Booking from "../../sections/Booking/Booking";
import Testimonials from "../../sections/Testimonials/Testimonials";
import Header from "../../sections/Header/Header.jsx";
import Blog from "../../sections/Blog/Blog";

import homepageBg from "../../assets/images/header/homepage-bg.webp";

import { useContext, useEffect } from "react";
import { UserContext } from "../../App";

export default function HomePage() {
  const [navigation, setNavigation] = useContext(UserContext);

  useEffect(() => {
    setNavigation("home");
  }, [setNavigation]);

  return (
    <div>
      <Header
        bgImage={homepageBg}
        text="Once a year go someplace you've
        <br/>  never been before"
      />
      <Booking />
      <OurServices />
      <AboutUs />
      <Blog />
      <FAQ />
      <Testimonials />
      {/* test for git pull */}
    </div>
  );
}
