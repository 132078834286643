import React, { useEffect } from "react";
import "./Aboutus.scss";
import AboutUs from "../../sections/AboutUs/AboutUs";
import WhoWeAre from "./WhoWeArePage/WhoWeAre";
import Fade from "react-reveal/Fade";
import { useContext } from "react";
import { UserContext } from "../../App";

const AboutusPage = () => {
  const [navigation, setNavigation] = useContext(UserContext);
  useEffect(() => {
    setNavigation("about");
  });

  return (
    <div className="">
      <div className="aboutus__container">
        <div className="aboutus__text">
          <Fade bottom>
            <h1>About us</h1>
          </Fade>
          <Fade bottom>
            <p>
            Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.
            </p>
          </Fade>
        </div>
      </div>
      <AboutUs />
      <WhoWeAre />
    </div>
  );
};

export default AboutusPage;
