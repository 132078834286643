import { NavLink, Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

import "./Navbar.scss";

export default function Navbar() {
  const [showDestinations, setShowDestinations] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();

  const links = ["Home", "Destination", "Services", "About", "Contact"];
  const destinations = ["destination 1", "destination 2", "destination 3"];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.body.getBoundingClientRect().top <= -200) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    });
  }, []);

  return (
    <main className="navbar">
      <div className="nav-container">
        <Link to="/">
          <div className="logo">HOTELL</div>
        </Link>

        <ul className="ul">
          {links.map((link) => {
            if (link === "Destination") {
              return (
                <div
                  onMouseOver={() => setShowDestinations(true)}
                  onMouseLeave={() => setShowDestinations(false)}
                  onClick={() => navigate("/destination")}
                  className="destinations"
                  key={link}
                >
                  <button className="destination">
                    {link}{" "}
                    <RiArrowDropDownLine
                      size={24}
                      className={showDestinations ? "rotate" : ""}
                    />
                  </button>

                  <ul
                    className={
                      showDestinations
                        ? "destinations-ul show"
                        : "destinations-ul"
                    }
                    onMouseOver={() => setShowDestinations(true)}
                    onMouseLeave={() => setShowDestinations(false)}
                  >
                    {destinations.map((destination) => (
                      <li key={destination}>
                        <button>{destination}</button>
                      </li>
                    ))}
                  </ul>
                </div>
              );
            } else {
              return (
                <li key={link}>
                  <NavLink
                    to={link === "Home" ? "/" : `/${link.toLocaleLowerCase()}`}
                    className="nav"
                  >
                    {link}
                  </NavLink>
                </li>
              );
            }
          })}
        </ul>

        <div className="tel">
          <span className="phone">
            <BsFillTelephoneFill />
          </span>

          <span className="no">+123-489-9381</span>
        </div>

        <div className="mobile">
          <button className="open" onClick={() => setShowNav(true)}>
            <GiHamburgerMenu color="#fff" size={30} />
          </button>

          <div
            className={showNav ? "modal show-modal" : "modal"}
            onClick={() => setShowNav(false)}
          ></div>

          <ul className={showNav ? "mobile-ul show-nav" : "mobile-ul"}>
            <button className="close" onClick={() => setShowNav(false)}>
              <GrClose size={30} />
            </button>

            {links.map((link) => {
              if (link === "Destination") {
                return (
                  <div className="destinations" key={link}>
                    <button
                      className="destination"
                      onClick={() => setShowDestinations((prev) => !prev)}
                    >
                      {link}{" "}
                      <RiArrowDropDownLine
                        size={30}
                        className={showDestinations ? "rotate" : ""}
                      />
                    </button>

                    <ul
                      className={
                        showDestinations
                          ? "destinations-ul show"
                          : "destinations-ul"
                      }
                    >
                      {destinations.map((destination) => (
                        <li key={destination}>
                          <button>{destination}</button>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              } else {
                return (
                  <li key={link}>
                    <NavLink
                      to={
                        link === "Home" ? "/" : `/${link.toLocaleLowerCase()}`
                      }
                      className="nav"
                      onClick={() => setShowNav(false)}
                    >
                      {link}
                    </NavLink>
                  </li>
                );
              }
            })}

            <Link to="#" className="tel tel-mobile">
              <span className="phone">
                <BsFillTelephoneFill />
              </span>

              <span className="no">+123-489-9381</span>
            </Link>
          </ul>
        </div>
      </div>
    </main>
  );
}
