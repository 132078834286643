import React from "react";

import { MdLocationOn } from "react-icons/md";

import "./PlaceCard.styles.scss";

const PlaceCard = ({ data }) => {
  const { image, title, location, price } = data;
  return (
    <div className="place-card">
      <img src={image} alt="" />
      <div>
        <h2>{title}</h2>
        <p>${price}</p>
      </div>
      <p>
        <MdLocationOn />
        {location}
      </p>
    </div>
  );
};

export default PlaceCard;
