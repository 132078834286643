import "./InstagramPosts.styles.scss";
import PostCard from "../../components/PostCard/PostCard";
import { postBanners } from "../../assets/data";

function InstagramPosts() {
  return (
    <>
      <div className="Container">
        {" "}
        <div className="posts-header">
          <h2 className="posts-title" data-aos="fade-right">
            INSTAGRAM
          </h2>
          <p className="posts-subtitle" data-aos="fade-left">
            A small river named Duden flows by their place and supplies it with
            the necessary regelialia. It is a paradisematic country, in which
            roasted parts of sentences fly into your mouth.
          </p>
        </div>
      </div>
      <div className="post-container">
        {/*       
      <div className="post-inner-container"  ref={postContainer} style={{ transform: `translateX(${scrollLength}px)`}}>
        {postBanners && postBanners.map((img, i) => <PostCard key={i} img={img} />)}
      </div> */}
        <div className="insta__container" data-aos="fade-up">
          {postBanners.map((post, i) => (
            <PostCard key={i} img={post} />
          ))}
        </div>
      </div>
    </>
  );
}

export default InstagramPosts;

/*

  <div className="posts-header">
        <h2 className="posts-title" data-aos="fade-right">
          INSTAGRAM
        </h2>
        <p className="posts-subtitle" data-aos="fade-left">
          A small river named Duden flows by their place and supplies it with
          the necessary regelialia. It is a paradisematic country, in which
          roasted parts of sentences fly into your mouth.
        </p>
      </div>


*/
