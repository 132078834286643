import React from "react";
import "./Header.styles.scss";
import Fade from "react-reveal/Fade";

const Header = ({ bgImage, text }) => {
  const style = {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url(${bgImage})`,
  };
  return (
    <header style={style}>
      <Fade bottom duration={2500}>
        <h1>
          {text.split("<br/>")[0]} <br />
          {text.split("<br/>")[1]}
        </h1>
        <button>See video</button>
      </Fade>
    </header>
  );
};

export default Header;
