import './Button.styles.scss';

const Button = ({ onClick, showBtn, children }) => {
  return (
    <button className={`btn ${showBtn ? 'anchor-hover' : ''}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
